import NotoSansKRBold from '@global/Asset/fonts/NotoSansKR-Bold.otf'
import NotoSansKRLight from '@global/Asset/fonts/NotoSansKR-Light.otf'
import NotoSansKRMedium from '@global/Asset/fonts/NotoSansKR-Medium.otf'
import NotoSansKRRegular from '@global/Asset/fonts/NotoSansKR-Regular.otf'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NotoSansKRLight';
    src: local('NotoSansKRLight'), local('NotoSansKRLight');
        font-style: normal;
        src: url(${NotoSansKRLight}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKRMedium';
    src: local('NotoSansKRMedium'), local('NotoSansKRMedium');
        font-style: normal;
        src: url(${NotoSansKRMedium}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKRRegular';
    src: local('NotoSansKRRegular'), local('NotoSansKRRegular');
        font-style: normal;
        src: url(${NotoSansKRRegular}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKRBold';
    src: local('NotoSansKRBold'), local('NotoSansKRBold');
        font-style: normal;
        src: url(${NotoSansKRBold}) format('opentype');
  }

  *, *::before, *::after {
    box-sizing: border-box;
    font-family: 'NotoSansKRLight', sans-serif !important;
  }

  &:root{
    font-family: 'NotoSansKRLight', sans-serif !important;
  }

  body{
    font-family: 'NotoSansKRLight', sans-serif !important;
  }
`

export default GlobalStyle
